import React from 'react'
import { useLocation } from 'react-router'
import { Menu as UikitMenu, useMatchBreakpoints, DropdownMenuItemType } from 'v3s-uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceVvsUsdc } from 'state/farms/hooks'
import { useProfile } from 'state/profile/hooks'
import { isBlindMode, isCountdown, showVvsPrice } from 'utils'
import { registerToken } from 'utils/wallet'
import tokens from 'config/constants/tokens'
import config from './config/config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const vvsPriceUsd = usePriceVvsUsdc()
  const { profile } = useProfile()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()
  const { isMobile } = useMatchBreakpoints()

  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const mobileLinks = [
    {
      label: 'Home',
      href: 'https://v3s.finance',
      type: DropdownMenuItemType.HOMEPAGE,
      showItemsOnMobile: false,
    },
    {
      label: 'Pacific',
      icon: 'Pacific',
      href: 'https://v3s.finance/pacific',
      type: DropdownMenuItemType.HOMEPAGE,
      showItemsOnMobile: false,
    },
    {
      label: 'Atlantic',
      icon: 'Atlantic',
      href: 'https://v3s.finance/atlantic',
      type: DropdownMenuItemType.HOMEPAGE,
      showItemsOnMobile: false,
    },
    {
      label: 'Caribbean',
      icon: 'Caribbean',
      href: 'https://v3s.finance/caribbean',
      type: DropdownMenuItemType.HOMEPAGE,
      showItemsOnMobile: false,
    },
    {
      label: '',
      href: '/',
      icon: 'More',
      hideSubNav: true,
      items: [
        {
          label: 'Poseidon',
          href: 'https://www.v3s.finance/poseidon',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'Ariel',
          href: 'https://www.v3s.finance/ariel',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'Regulations',
          href: 'https://v3s.finance/regulations',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'Docs',
          href: 'https://docs.v3s.finance',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ],
    },
  ]

  return (
    <UikitMenu
      userMenu={isCountdown() ? null : <UserMenu />}
      globalMenu={isCountdown() ? null : <GlobalSettings />}
      isDark={isDark}
      isBlindMode={isBlindMode()}
      toggleTheme={toggleTheme}
      t={t}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      vvsPriceUsd={vvsPriceUsd.toNumber()}
      showVvsPrice={showVvsPrice()}
      links={isMobile ? mobileLinks : config(t)}
      subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      footerLinks={footerLinks(t)}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyVvsLabel={t('Buy V3S')}
      profile={{
        username: profile?.username,
        image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
        profileLink: '/profile',
        noProfileLink: '/profile',
        showPip: !profile?.username,
      }}
      iconCallback={() => registerToken(tokens.vvs.address, tokens.vvs.symbol, tokens.vvs.decimals)}
      vvsAddress={tokens.vvs.address}
      {...props}
    />
  )
}

export default Menu
