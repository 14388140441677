import { MenuItemsType, DropdownMenuItemType } from 'v3s-uikit'
import { ContextApi } from 'contexts/Localization/types'
import { isBlindMode } from 'utils'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const desktopLinks = [
  {
    label: 'Home',
    href: 'https://v3s.finance',
    type: DropdownMenuItemType.HOMEPAGE,
    showItemsOnMobile: false,
  },
  {
    label: 'Pacific',
    icon: 'Pacific',
    href: 'https://v3s.finance/pacific',
    type: DropdownMenuItemType.HOMEPAGE,
    showItemsOnMobile: false,
  },
  {
    label: 'Atlantic',
    icon: 'Atlantic',
    href: 'https://v3s.finance/atlantic',
    type: DropdownMenuItemType.HOMEPAGE,
    showItemsOnMobile: false,
  },
  {
    label: 'Caribbean',
    icon: 'Caribbean',
    href: 'https://v3s.finance/caribbean',
    type: DropdownMenuItemType.HOMEPAGE,
    showItemsOnMobile: false,
  },
  {
    label: 'Swap',
    href: '/swap',
    showItemsOnMobile: false,
  },
  {
    label: 'Poseidon',
    icon: 'Poseidon',
    href: 'https://www.v3s.finance/poseidon',
    type: DropdownMenuItemType.HOMEPAGE,
    showItemsOnMobile: false,
  },
  {
    label: 'Ariel',
    icon: 'Ariel',
    href: 'https://www.v3s.finance/ariel',
    type: DropdownMenuItemType.HOMEPAGE,
    showItemsOnMobile: false,
  },
  {
    label: 'Regulations',
    icon: 'Regulations',
    href: 'https://v3s.finance/regulations',
    type: DropdownMenuItemType.HOMEPAGE,
    showItemsOnMobile: false,
  },
  {
    label: 'Docs',
    icon: 'Docs',
    href: 'https://docs.v3s.finance',
    type: DropdownMenuItemType.EXTERNAL_LINK,
    showItemsOnMobile: false,
  },
]

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => desktopLinks

export default config
